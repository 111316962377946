
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query CreateIRecNFTQuery ($id: Int!){
    project_items(where: {id: {_eq: $id}}) {
      project {
      collection_id
      project_name
      project_owner_did
      project_irec {
        equipped_capacitor
        expired_at
        production_at
        project_address
        project_country
        project_fuel
        project_id
        register_at
        project_technology
      }
    }
    agent_serial_number
    agent_count
    agent_at
    id
    }
  }
`

function fetchCreateIRecNFTQuery(id: any) {
  return fetchGraphQL(operation, 'CreateIRecNFTQuery', { id: id })
}

export default fetchCreateIRecNFTQuery
