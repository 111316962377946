
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query IRecDetailsQuery($project_id: Int!) {
    project_irec(where: {project_id: {_eq: $project_id}}) {
      equipped_capacitor
      expired_at
      id
      production_at
      project_address
      project_country
      project_fuel
      project_id
      project_technology
      register_at
      project {
        collection_id
        homepage
        project_assets
        project_name
        project_owner_did
        project_standard
        project_type
      }
    }
  }
`

function fetchIRecDetailsQuery(projectId: string) {
  return fetchGraphQL(operation, 'IRecDetailsQuery', { project_id: projectId })
}

export default fetchIRecDetailsQuery
