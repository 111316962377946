




































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import Empty from '@/components/Empty/index.vue'
import RegisterRecDialog from '@/views/center/greenCenter/components/I-REC/RegisterRecDialog.vue'
import CreateCollectionRecDialog from '@/views/center/greenCenter/components/I-REC/CreateCollectionRecDialog.vue'
import Pagination from '@/components/Pagination/index.vue'
import CastingNftRecDialog from '@/views/center/greenCenter/components/I-REC/CastingNftRecDialog.vue'
import AssetOwnershipDialog from '@/views/center/greenCenter/components/I-REC/AssetOwnershipDialog.vue'
import fetchIRecDetailsQuery from '@/api/husuraApi/irecDetailsApi'
import fetchVcsListQuery from '@/api/husuraApi/vcsListApi'

@Component({
  name: 'RecDetailsDrawer',
  components: {
    CastingNftRecDialog,
    Empty,
    LensSpan,
    ListTile,
    Pagination,
    RegisterRecDialog,
    CreateCollectionRecDialog,
    AssetOwnershipDialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectId: ''
      }
    }
  }) private initData!: {
    projectId: string
  }

  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private isRegisterRecDialogVisible = false
  private isCreateCollectionRecDialogVisible = false
  private isCastingNftRecDialogVisible = false
  private isAssetOwnershipDialogVisible = false
  private irecList = []
  private projectItemId = ''

  private irecDetailsList= [
    {
      equipped_capacitor: '',
      expired_at: '',
      id: '',
      production_at: '',
      project_address: '',
      project_country: '',
      project_fuel: '',
      project_id: '',
      project_technology: '',
      register_at: '',
      project: {
        collection_id: null,
        homepage: '',
        project_assets: null,
        project_name: '',
        project_owner_did: '',
        project_standard: '',
        project_type: ''
      }
    }
  ]

  private projectId = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectId = this.initData.projectId
      this.IRecDetailsQuery()
      this.irecListQuery()
    }
  }

  private async irecListQuery(refresh = true) {
    if (refresh) {
      fetchVcsListQuery(this.pageSize, (this.page - 1) * this.pageSize, this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.irecList = data.project_items
          this.totalCount = data.project_items_aggregate.aggregate.count ?? 0
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private IRecDetailsQuery(refresh = true) {
    if (refresh) {
      fetchIRecDetailsQuery(this.projectId)
        .then(({ data: res }) => {
          const { data } = res
          this.irecDetailsList = data.project_irec
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private handleRegister() {
    this.isRegisterRecDialogVisible = true
  }

  private handleCollection() {
    this.isCreateCollectionRecDialogVisible = true
  }

  private handleCasting(e: any) {
    // if (e.project.collection_id === null) {
    //   this.$message.warning('请先创建Collection')
    //   return
    // }
    this.projectItemId = e.id
    if (!e.right_status) {
      this.isAssetOwnershipDialogVisible = true
    } else {
      this.isCastingNftRecDialogVisible = true
    }
  }

  private handleLink(link: any) {
    window.open(link, '_blank')
  }
}
