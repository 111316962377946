
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  mutation UpdateProjectAssetsMutation($id: Int!, $project_assets: numeric!) {
    update_projects(where: {id: {_eq: $id}}, _set: {project_assets: $project_assets}) {
      returning {
        id
      }
    }
  }
`

function fetchUpdateProjectAssetsMutation(id: String, projectAssets: Number) {
  return fetchGraphQL(operation, 'UpdateProjectAssetsMutation', { id: id, project_assets: projectAssets })
}

export default fetchUpdateProjectAssetsMutation
