
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  mutation RegisterProjectMutation($object: projects_insert_input!) {
    insert_projects_one(object: $object) {
      id
    }
  }
`

function fetchRegisterProjectMutation(object: object) {
  return fetchGraphQL(operation, 'RegisterProjectMutation', { object: object })
}

export default fetchRegisterProjectMutation
