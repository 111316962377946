











































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import fetchRegisterVcsMutation from '@/api/husuraApi/registerVcsApi'
import fetchQueryVcsNumQuery from '@/api/husuraApi/queryVcsNumApi'
import fetchUpdateProjectAssetsMutation from '@/api/husuraApi/updateProjectAssetsApi'
import fetchGetUserApiQuery from '@/api/husuraApi/getUserApi'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'

@Component({
  name: 'RegisterRecDialog'
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        projectId: ''
      }
    }
  }) private initData!: {
    projectId: string
  }

  private projectId = ''
  private agentAt = ''
  private agentCount = ''
  private agentSerialNumber = ''
  private userDid = 'did:kilt:4qBUs774AdxHD1WShvyF1TWBMtUTX1w2BestE9NqWsTaajUC'
  private userId = 1

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.projectId = this.initData.projectId
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private async save() {
    if (!this.agentAt) {
      this.$message.warning('请选择I-RECs发行日期')
      return
    }
    if (!this.agentCount) {
      this.$message.warning('请输入I-RECs数量')
      return
    }
    if (!this.agentSerialNumber) {
      this.$message.warning('请输入I-RECs序列号')
      return
    }
    const token = UserModule.oauth.accessToken
    const jwt = UserService.decodeToken(token)
    let userDID
    if (jwt) {
      userDID = jwt.sub
    }
    if (!userDID) {
      this.$message.warning('未获取到DID信息，请重新登录')
      return
    }
    this.userDid = userDID
    let userId = 0
    await fetchGetUserApiQuery(this.userDid)
      .then(async({ data: res }) => {
        const { data } = res
        if (data) {
          userId = data.users[0].id
        }
      })
      .catch(error => {
        console.error(error)
      })
    if (userId === 0) {
      this.$message.warning('未获取到用户信息，请重新登录')
      return
    }
    this.userId = userId
    fetchRegisterVcsMutation(this.agentAt, this.agentCount, this.agentSerialNumber, this.projectId, this.userDid, this.userId)
      .then(({ data: res }) => {
        const { data } = res
        if (data) {
          fetchQueryVcsNumQuery(this.projectId)
            .then(({ data: res }) => {
              const { data } = res
              if (data) {
                fetchUpdateProjectAssetsMutation(this.projectId, data.project_items_aggregate.aggregate.sum.agent_count)
                  .then(({ data: res }) => {
                    const { data } = res
                    if (data) {
                      this.close(true)
                    }
                  })
                  .catch(error => {
                    console.error(error)
                  })
              }
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  private close(refresh = false) {
    this.$emit('close', refresh)
  }
}
