
































































































































import { Component, Vue } from 'vue-property-decorator'
import Container from '@/components/Container/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import CreateProjectDrawer from '@/views/center/greenCenter/components/CreateProjectDrawer.vue'
import CenterSearchHeader from '@/views/center/greenCenter/components/CenterSearchHeader.vue'
import RecDetailsDrawer from '@/views/center/greenCenter/components/I-REC/RecDetailsDrawer.vue'
import fetchCenterQuery from '@/api/husuraApi/centerApi'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'

@Component({
  name: 'GreenCenter',
  components: {
    RecDetailsDrawer,
    Container,
    Pagination,
    CreateProjectDrawer,
    CenterSearchHeader
  }
})
export default class extends Vue {
  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private isCreateProjectDrawer = false
  private showRecDetailsDrawer = false
  private centerItemsList = []
  private projectId = ''

  created() {
    this.fetchCenterQuery()
  }

  private fetchCenterQuery(refresh = true) {
    if (refresh) {
      const token = UserModule.oauth.accessToken
      const jwt = UserService.decodeToken(token)
      let userDID
      if (jwt) {
        userDID = jwt.sub
      }
      if (!userDID) {
        this.$message.warning('未获取到DID信息，请重新登录')
        return
      }
      fetchCenterQuery(this.pageSize, (this.page - 1) * this.pageSize, userDID, 'I-REC')
        .then(({ data: res }) => {
          const { data } = res
          this.centerItemsList = data.projects
          this.totalCount = data.projects_aggregate.aggregate.count ?? 0
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private onRowClick(e: any) {
    this.projectId = e.id
    this.showRecDetailsDrawer = true
  }

  private handleLink(link: any) {
    window.open(link.homepage, '_blank')
  }
}
