
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  mutation RegisterVcsMutation($agent_at: date!, $agent_count: Int!, $agent_serial_number: String!, $project_id: Int!, $user_did: name!, $user_id: Int!) {
    insert_project_items_one(object: {agent_at: $agent_at, agent_count: $agent_count, agent_serial_number: $agent_serial_number, project_id: $project_id, user_did: $user_did, user_id: $user_id}) {
      id
    }
  }
`

function fetchRegisterVcsMutation(agentAt: String, agentCount: String, agentSerialNumber: String, projectId: String, userDid: String, userId: number) {
  return fetchGraphQL(operation, 'RegisterVcsMutation', { agent_at: agentAt, agent_count: agentCount, agent_serial_number: agentSerialNumber, project_id: projectId, user_did: userDid, user_id: userId })
}

export default fetchRegisterVcsMutation
