
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query QueryVcsNumQuery($project_id: Int!) {
    project_items_aggregate(where: {project_id: {_eq: $project_id}}) {
      aggregate {
        sum {
          agent_count
        }
      }
    }
  }
`

function fetchQueryVcsNumQuery(projectId: string) {
  return fetchGraphQL(operation, 'QueryVcsNumQuery', { project_id: projectId })
}

export default fetchQueryVcsNumQuery
